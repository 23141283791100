<template>
  <component :is="tag" v-on="$listeners">
    <!-- <b-avatar
      size="42"
      :src="user.avatar"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="transparent"
    /> -->
    <b-avatar size="42" :src="FILESURL + user.avatar" />
    <div class="chat-info flex-grow-1">
      <h4 class="mb-0">
        <b>{{ user.fullName }}</b>
      </h4>
      <!-- <p class="card-text text-truncate">
        {{ user.role }}
      </p> -->
      <p class="card-text text-truncate">
        <b>
          {{ user.new_messages ? user.new_messages.split(";")[0] : user.role }}</b
        >
      </p>
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <!-- <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(user.chat.lastMessage.time, {
          hour: "numeric",
          minute: "numeric",
        })
      }}</small> -->
      <b-badge
        v-if="user.new_message_count"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.new_message_count }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import { FILESURL } from "@/config";
import useChat from "./useChat";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style>
</style>
